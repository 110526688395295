import { SiteInfo } from "../types";
import { fastQRGenerator } from "./fastqrgenerator.com";
import { localhostQRCreator } from "./localhost";
import { onlineQrCode } from "./onlineqrcode.com";
import { QRCreator } from "./qrcreator.com";

export const DOMAINS = {
  "qrcreator.com": "qrcreator.com",
  "fastqrgenerator.com": "fastqrgenerator.com",
  "onlineqrcode.com": "onlineqrcode.com",
  "localhost:8000": "localhost:8000",
};

export const domains: {[x: string]: SiteInfo} = {
  "qrcreator.com": QRCreator,
  "fastqrgenerator.com": fastQRGenerator,
  "onlineqrcode.com": onlineQrCode,
  "localhost:8000": localhostQRCreator,
};

