"use client";

import { useEffect } from "react";
import { useSearchParams } from "next/navigation";

import { setCookie } from "@/services/CookiesService";

import { UTMS } from "./types";

export const StoreUtms = () => {
  const searchParams = useSearchParams();

  const UTM_SOURCES = [
    "utm_source",
    "utm_campaign",
    "utm_medium",
    "gclid",
    "msclkid",
    "tag",
    "utm_term",
    "utm_content",
    "utm_adgroup",
    "utm_network",
    "utm_device",
    "utm_devicemodel",
    "utm_matchtype",
    "utm_loc_physical_ms",
    "utm_campaigntype",
  ];

  useEffect(() => {
    const keys = searchParams.keys();
    const utmsMatchQueryParams = {};

    for (const key of keys) {
      if (UTM_SOURCES.includes(key)) {
        utmsMatchQueryParams[key] = searchParams.get(key);
      }
    }

    if (Object.keys(utmsMatchQueryParams).length !== 0) {
      setCookie(UTMS, utmsMatchQueryParams);
    }
  }, []);

  return null;
};
