import { Inter, Arimo } from "next/font/google";

export const inter = Inter({
  display: "swap",
  style: ["normal"],
  subsets: ["latin"],
  weight: ["400","600","700"],
  variable: "--font-inter",
  adjustFontFallback: false
});

export const arimo = Arimo({
  display: "swap",
  style: ["normal"],
  subsets: ["latin"],
  weight: ["700"],
  variable: "--font-arimo",
  adjustFontFallback: false
});

export const fonts = {
  inter,
  arimo
};
