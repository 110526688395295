"use client";

import React from "react";
import ReactModal from "react-modal";
import { FiX } from "react-icons/fi";

import { useModal } from "@/hooks/useModal";
import { ModalContextProps } from "./types";

let ModalContext: React.Context<ModalContextProps> | undefined;
const { Provider } = (ModalContext = React.createContext(null));

const ModalProvider = ({ children }: any) => {
  const {
    handleModal,
    setModalContent,
    isModalOpened,
    modalContent,
    modalProps,
    hideCloseButton,
    notClosable,
    modalClassName,
    modalOverlayClassName,
    isLoading,
    onCancelCallback,
    setIsLoading,
  } = useModal();

  return (
    <Provider
      value={{
        handleModal,
        setModalContent,
        setIsLoading,
        modalContent,
        isModalOpened,
        modalClassName,
        modalOverlayClassName,
        isLoading,
      }}
    >
      {children}
      <ReactModal
        isOpen={isModalOpened}
        className={`u-modal u-box u-box u-box--small u-text-center ${
          modalClassName || ""
        }`}
        overlayClassName={`u-modal__overlay ${modalOverlayClassName}`}
        ariaHideApp={false}
        shouldCloseOnEsc={!notClosable}
        shouldCloseOnOverlayClick={!notClosable}
        onRequestClose={() =>
          onCancelCallback ? onCancelCallback() : handleModal()
        }
        {...modalProps}
      >
        {!hideCloseButton && (
          <FiX
            onClick={() => {
              onCancelCallback ? onCancelCallback() : handleModal();
            }}
            size={20}
            className="u-modal__close"
          />
        )}
        {modalContent}
      </ReactModal>
    </Provider>
  );
};

export { ModalContext, ModalProvider };
