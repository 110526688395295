"use client";

import React from "react";

import { LayoutContextProps } from "./types";

let LayoutContext: React.Context<LayoutContextProps> | undefined;
const { Provider } = (LayoutContext =
  React.createContext<LayoutContextProps>(undefined));

const LayoutProvider = ({
  children,
  modalData: modalDataFromProps,
  genericErrors: genericErrorsFromProps,
}: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalData, setModalData] = React.useState<any>(modalDataFromProps);
  const [genericErrors, setGenericErrors] = React.useState(
    genericErrorsFromProps
  );
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  const [isQRLanding, setIsQRLanding] = React.useState(false);
  const [isSectionsMenuDisabled, setIsSectionsMenuDisabled] = React.useState(false);

  return (
    <Provider
      value={{
        modalData,
        setModalData,
        isLoading,
        setIsLoading,
        genericErrors,
        setGenericErrors,
        isMenuOpened,
        setIsMenuOpened,
        isQRLanding,
        setIsQRLanding,
        isSectionsMenuDisabled,
        setIsSectionsMenuDisabled
      }}
    >
      {children}
    </Provider>
  );
};

export { LayoutContext, LayoutProvider };
