import { DOMAINS, domains } from "./domains";
import { SiteInfo } from "./types";

const DEFAULT_SITE_DOMAIN = domains[DOMAINS["qrcreator.com"]];

export const getSiteInfo = (domainName: string): SiteInfo | undefined => {
  const formattedDomain = domainName?.split(".").slice(-2).join(".");
  const siteInfo = domains[formattedDomain] || DEFAULT_SITE_DOMAIN;

  return siteInfo;
};