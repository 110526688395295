import { ABCampaigns } from "@/services/ABTestService/types";



export const AB_CAMPAIGNS: ABCampaigns = {
  TRUSTPILOT_MODULE: {
    variant: "Control",
    id: "24",
  },
};
