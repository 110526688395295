"use client";
import { useEffect, createContext, useState } from "react";
import { initAmplitude } from "@/services/AmplitudeService/AmplitudeService";
import { QRType } from "@/types/qr";
import { IAmplitudeContext } from "./types";

export const AmplitudeContext = createContext<IAmplitudeContext>(undefined);
export const AmplitudeContextProvider = ({ children }) => {
  const [source, setSource] = useState("");
  const [qrSelected, setQrSelected] = useState<QRType | undefined>();
  const [data, setData] = useState();

  useEffect(() => {
    initAmplitude();
  }, []);

  const value = {
    source,
    setSource,
    data,
    setData,
    qrSelected,
    setQrSelected,
  };

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
};
