export const isClient = () => typeof window !== "undefined";

export const userAgent = () => navigator.userAgent;

export const isChrome = () => /Chrome/.test(userAgent());

export const isFirefox = () => /Firefox/.test(userAgent());

export const isSafari = () => /Safari/.test(userAgent()) && !isChrome();

export const isEdge = () => /Edg/.test(userAgent());
