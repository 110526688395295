"use client";

import React from "react";
import { ChunksProgress, LoadingBarContextProps, ProgressState } from "./types";

function calculateWeightedAverage(percentages: Array<number>) {
  const numberOfPercentages = percentages.length;
  const individualWeight = 1 / numberOfPercentages;
  return percentages.reduce((sum, percentage) => {
    return sum + percentage * individualWeight;
  }, 0);
}

let LoadingBarContext: React.Context<LoadingBarContextProps>;
const { Provider } = (LoadingBarContext =
  React.createContext<LoadingBarContextProps>(undefined));

const LoadingBarProvider = ({ children }: any) => {
  const [progressState, setProgressState] = React.useState<ProgressState>([]);
  const [isLoading, setIsLoading] = React.useState([false]);
  const setProgressHandler = (id: number, progress: number) => {
    setProgressState((prev: number[]) => {
      const overridePrevState = [...prev];
      overridePrevState[id] = progress;
      return overridePrevState;
    });
  };

  const initChunksHandler = (id: number, chunksNumber: number) =>
    setProgressState((prev: ChunksProgress[]) => {
      const overrideState = [...prev];
      overrideState[id] = {
        ...overrideState[id],
        chunks: chunksNumber,
      };

      return overrideState;
    });

  const setChunkProgressHandler = (
    id: number,
    chunk: number,
    progress: number
  ) => {
    setProgressState((prev: ChunksProgress[]) => {
      const prevFileProgress = prev[id] as ChunksProgress;
      const overridedProgressList = [...(prevFileProgress?.progressList || [])];
      overridedProgressList[chunk] = progress;
      const overrideState = [...prev];
      overrideState[id] = {
        ...overrideState[id],
        progressList: overridedProgressList,
      };
      return overrideState;
    });
  };

  const getReducedChunksProgress = (id: number) => {
    const progress = progressState[id] as ChunksProgress;
    const chunks = progress.chunks;

    const mask = new Array(chunks).fill(0);
    const progressChunksList = (progress?.progressList || []).slice(0, chunks);
    const progressList = mask.map(
      (position, i) => position + progressChunksList[i] || 0
    );

    return calculateWeightedAverage(progressList);
  };

  const getProgressHandler = (id: number) => {
    if (typeof progressState[id] === "object") {
      return getReducedChunksProgress(id);
    }
    return (progressState[id] as number) || 0;
  };

  const removeProgressHandler = (id: number) => {
    // @ts-ignore
    setProgressState((prev) => {
      const overrided = [...prev];
      overrided.splice(id, 1);
      return overrided;
    });
  };

  const resetContext = () => setProgressState([]);
  const setIsLoadingHandler = (state, id) => {
    setIsLoading((prev) => {
      const newState = [...prev];
      newState[id] = state;
      return newState;
    });
  };

  return (
    <Provider
      value={{
        getProgress: getProgressHandler,
        setProgress: setProgressHandler,
        removeProgress: removeProgressHandler,
        initChunks: initChunksHandler,
        setChunkProgress: setChunkProgressHandler,
        resetContext,
        isLoading,
        setIsLoading: setIsLoadingHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { LoadingBarContext, LoadingBarProvider };
