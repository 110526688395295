import { find, forEach, isEmpty } from "lodash";
import { AB_CAMPAIGNS } from "@/internals/constants/testAB";
import { ABCampaigns } from "./types";

export class ABTestService {
  static _campaigns: ABCampaigns = AB_CAMPAIGNS;

  constructor(campaigns: ABCampaigns = {}) {
    ABTestService._campaigns = campaigns;
  }

  private static getVariantKeys() {
    const vwoVariations = (window as any)._vwo_exp;

    if (isEmpty(vwoVariations)) return undefined;

    const variations: { [key: string]: string } = {};

    forEach(vwoVariations, (variation, key) => {
      const selectedCombination = variation.combination_chosen;
      const variationValue = variation.comb_n[Number(selectedCombination)];
      variations[key] = variationValue;
    });
    return variations;
  }

  static setVWOCampaigns() {
    const campaignKeys = ABTestService.getVariantKeys();

    if (!campaignKeys) return undefined;

    Object.keys(campaignKeys).forEach((key) => {
      ABTestService.setVariant(key, campaignKeys[key]);
    });   
  }

  static getVariant(campaignKey: string) {
    const campaign = ABTestService._campaigns[campaignKey];
    return campaign?.variant;
  }

  static setVariant(campaignId: string, variant: string) {
    const campaign = find(ABTestService._campaigns, { id: campaignId });
    if (!campaign) return;
    campaign["variant"] = variant;
  }
}

 export const initABTestService = () =>{
  ABTestService.setVWOCampaigns();
}


 
